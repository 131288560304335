<template>
    <div>
        <v-menu v-if="menuItemsPromise"
            offset-y
            :close-on-content-click="false">
                <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon
                        :disabled="!!inactive"
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                    >
                        <v-icon>{{ status.length > 1 ? (active ? `mdi-${icons[0]}` : `mdi-${icons[1]}`) : `mdi-${icons[0]}` }}</v-icon>
                    </v-btn>
                    </template>
                    <span>{{ status.length > 1 ? (active ? $t(status[0]) : $t(status[1])) : $t(status[0]) }}</span>
                </v-tooltip>
            </template>

            <v-list>
                <v-list-item>
                    <v-autocomplete
                        v-model="menuSelection"
                        :items="menuItems"
                        item-text="name"
                        multiple
                        :label="$t(menuLabel)"
                    ></v-autocomplete>
                </v-list-item>
            </v-list>

            <v-card
                class="d-flex flex-row-reverse"
                flat
                tile
                >
                <v-btn class="mr-3 mb-3"
                    @click="doAction">
                    <v-icon small class="mr-1">mdi-send</v-icon>
                    {{ $t('send') }}
                </v-btn>
            </v-card>
        </v-menu>

        <v-tooltip bottom
            v-else>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disabled || !!inactive"
                    :color="actionColor"
                    @click="doAction">
                    <v-badge v-if="badgeText"
                        bordered
                        bottom
                        color="grey accent-4"
                        :content="badgeText"
                        offset-x="5"
                        offset-y="15"
                    >
                        <v-icon>{{ status.length > 1 ? (active ? `mdi-${icons[0]}` : `mdi-${icons[1]}`) : `mdi-${icons[0]}` }}</v-icon>
                    </v-badge>
                    <v-icon v-else>{{ status.length > 1 ? (active ? `mdi-${icons[0]}` : `mdi-${icons[1]}`) : `mdi-${icons[0]}` }}</v-icon>
                </v-btn>
            </template>
            <span>{{ status.length > 1 ? (active ? $t(status[0]) : $t(status[1])) : $t(status[0]) }}</span>
        </v-tooltip>
    </div>
</template>


<script>
export default {
    props: {
        id: Number,
        isActive: Boolean,
        status: Array,
        icons: Array,
        menuItemsPromise: {
            default() {
                return null
            },
            type: Promise,
        },
        menuLabel: {
            default() {
                return ''
            },
            type: String,
        },
        action: {
            default() {
                return null
            },
            type: Function,
        },
        type: String,
        inactive: Boolean,
        badgeText: {
            default() {
                return null
            },
            type: String,
        },
    },

    data() {
        return {
            active: false,
            menuSelection: null,
            disabled: false,
            actionResult: true,
            menuItems: null,
            actionColor: '',
        }
    },

    methods: {
        setActionColor(color) {
            this.actionColor = color
        },

        doAction() {
            this.disabled = true

            if (this.action) {
                if (this.type === 'process') {
                    this.active = !this.active
                }
                this.action()
                    .then(response => {
                        if (response) {
                            this.actionColor = response.success ? 'success' : 'error'
                            this.active = this.type === 'toggle' ? !response.active : !this.active
                        }
                    })
                    .catch(err => {
                        this.actionColor = 'error'
                    })
                    .finally(() => {
                        this.disabled = false
                        setTimeout(() => { this.actionColor = '' }, 2000)
                    })
            }
        },
    },

    created() {
        this.active = this.isActive

        if (this.menuItemsPromise) {
            this.menuItemsPromise
                .then(result => {
                    this.menuItems = result
                })
        }
    },

    watch: {
        isActive(val) {
            this.active = val
        },
    },
}
</script>
